if (window.toggleBurgerMenu === undefined) {
  window.toggleBurgerMenu = true

  const input = document.getElementById('burger-menu-checkbox')
  const openBurgerMenu = document.getElementById('open-burger-menu')

  input.addEventListener('click', () => {
    if (input.checked) {
      openBurgerMenu.style.right = "0"
      document.body.style.overflow = 'hidden'
    } else {
      openBurgerMenu.style.right = "-100%"
      document.body.style.overflow = 'auto'
    }
  })
}
